import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducer';

import { getHostname } from '../helpers';
import { organizationApi, sendersApi } from '../app/admin/state';
import { notificationApi, userSettingsApi, delegatedAccessApi } from '../app/user/state';

const enableReduxDevtools = getHostname()?.includes('localhost');

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleWare) =>
        getDefaultMiddleWare().concat([
            sendersApi.middleware,
            organizationApi.middleware,
            notificationApi.middleware,
            userSettingsApi.middleware,
            delegatedAccessApi.middleware,
        ]),
    devTools: enableReduxDevtools,
});

export type RootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;

export default store;
