import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IDelegatedAccess } from 'src/interfaces';
import { staticConfig } from '../../../../config/static';
import { prepareHeaders } from '../../../../redux';

const baseUrl = staticConfig.apiBaseUrl;
const tagTypes = ['DelegatedAccess'];
const defaultMailboxId = +localStorage.getItem('mailboxId');

export const delegatedAccessApi = createApi({
    reducerPath: 'delegatedAccessApi',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders,
    }),
    tagTypes,
    endpoints: (builder) => ({
        getDelegatedAccess: builder.query<IDelegatedAccess[], void>({
            query: () => {
                return `mailbox/access`;
            },
            providesTags: tagTypes,
        }),
        getDelegatedAccessor: builder.query<IDelegatedAccess[], number>({
            query: (mailboxId = defaultMailboxId) => {
                return `mailbox/${mailboxId}/access`;
            },
            providesTags: tagTypes,
        }),
    }),
});
export const { useGetDelegatedAccessQuery, useGetDelegatedAccessorQuery } = delegatedAccessApi;
