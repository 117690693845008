import { staticConfig } from '../../src/config/static';
import * as api from '../middleware/api.middleware';
import {
    IPortalInfoItem,
    IBrandingConfiguration,
    IPortalExpandItem,
    IPortalUpdate,
    IPortalTemplateLinks,
    IPortalLinks,
    IportalUpdateItem,
} from '../interfaces/portal.interfaces';
import { IConfigFieldEntry, IIdentityType } from '../interfaces/userIdentity.interfaces';
import { IResponseCollection } from '../interfaces/general.interfaces';

const serviceUrl = '/portalservice/v2';

export function getPortalInfoByDomain(domainName: string) {
    return api.get<IPortalInfoItem>(`${serviceUrl}/portals/portal-info?domainName=${domainName}`);
}

export function getPortalBranding(portalId: number) {
    return api.get<IBrandingConfiguration>(`${serviceUrl}/portals/${portalId}/portal-branding`);
}

export function getPortalBrandingByDomain(domainName: string) {
    return api.get<IBrandingConfiguration>(`${serviceUrl}/portals/portal-branding?domainName=${domainName}`);
}

export function getPortalIdentityTypes(portalId: number): Promise<IIdentityType[]> {
    return api.get<IIdentityType[]>(`${serviceUrl}/portal-search-identity-types/${portalId}/config`);
}

export function getPortalIdentityTypesConfig(_portalId: number): Promise<IResponseCollection<IConfigFieldEntry>> {
    return api.get<IResponseCollection<IConfigFieldEntry>>(`${serviceUrl}/ui-fields?resolveValues=true`);
}
export function getPortalByOrganization(_organizationId: number): Promise<IResponseCollection<IPortalExpandItem>> {
    return api.get<IResponseCollection<IPortalExpandItem>>(
        `${serviceUrl}/portals?$expands=portaltemplate&organizationid=${_organizationId}`
    );
}

export function getPortalTemplateLink(portalTemplateId: number): Promise<IResponseCollection<IPortalTemplateLinks>> {
    return api.get<IResponseCollection<IPortalTemplateLinks>>(
        `${serviceUrl}/portal-template-links?portalTemplateId=${portalTemplateId}`
    );
}

export function getPortalLink(portalId: number, languageId?: number): Promise<IResponseCollection<IPortalLinks>> {
    return api.get<IResponseCollection<IPortalLinks>>(
        `${serviceUrl}/portal-links?portalId=${portalId}&languageId=${languageId}`
    );
}

export function updatePortalLink(data: IPortalUpdate) {
    return api.put<IportalUpdateItem>(`${serviceUrl}/portal-links`, data);
}
